import React, { useContext} from "react";
import { v4 as uuidv4 } from "uuid";

import { SocketContext } from "../../context/SocketContext";

import SpanBoton from "./SpanBoton";

const BotonPedidoTrabajando = ({ ord_trabajando }) => {
  //use context Socket
  const { socket } = useContext(SocketContext);

  const {
    id,
    estilo,
    ingredientes,
    termino,
    complementos,
    cantidad,
    orden,
    comentarios,
    hotel
  } = ord_trabajando;

  const onClick = (hotel, id) => {
    //Finalizar Ticket
    socket.emit("finalizar-orden", hotel, id);
  };


  return (

    // Evento Click para cambiar el estatus del Pedido TRABAJANDO a FINALZIADO
    <div
      onClick={() => onClick(hotel, id)}
      style={{
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        KhtmlUserSelect: "none",
        msUserSelect: "none",
      }}
    >

      {/* Boton General: # Orden + Descripcion del Pedido */}
      <div
        style={{
          marginTop: "1em",
          height: "auto",
          display: "flex",
          alignItems: "stretch",
        }}
      >
        {/* Boton #Orden */}
        <div
          style={{
            color: "black",
            fontSize: "2em",
            margin: "6px",
            height: "auto",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            verticalAlign: "middle",
            lineHeight: "auto",
          }}
          className="btn grey lighten-4"
        >
          {orden}
        </div>

        {/* Boton Cantidad + Estilo + Complementos */}
        <div
          style={{
            color: "black",
            fontSize: "2em",
            margin: "6px",
            height: "auto",
            width: "100%",
          }}
          className="btn grey lighten-4"
        >
          {cantidad} - {estilo}

          {/* Span Ingredientes, Terminos y Complementos */}
          <div
            className="valign-wrapper"
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {!!termino && (
              <SpanBoton
                key={uuidv4()}
                descripcion={termino}
                color="deep-orange accent-3"
              />
            )}
            {!!ingredientes &&
              ingredientes.map((i) => (
                <SpanBoton key={uuidv4()} descripcion={i} color="blue" />
              ))}
            {!!complementos &&
              complementos.map((c) => (
                <SpanBoton
                  key={uuidv4()}
                  descripcion={c}
                  color="brown lighten-2"
                />
              ))}
              {!!comentarios && <SpanBoton descripcion={comentarios} color="purple lighten-3"/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotonPedidoTrabajando;
