import React, { useContext } from "react";
import logoHVB from "../assets/img/logo-bvg.png";
import logoHDA from "../assets/img/logo-hda.png";
import { GLOBAL_HOTEL } from "../variables";


import { MostrarPaginaContext } from "../context/MostrarPaginaContext";

import Estilos from "../components/Estilos";
import RadioButtonIdioma from "../components/RadioButtonIdioma";
import Ingredientes from "../components/Ingredientes";
import Terminos from "../components/Terminos";
import Complementos from "../components/Complementos";
import AtrasSiguiente from "../components/AtrasSiguiente";
import Pedido from "../components/Pedido";
import CantidadEggs from "../components/CantidadEggs";
import OrdenFinalizada from "../components/OrdenFinalizada";

import { useSpinner } from "../hooks/useSpinner";
import Spinner from "../components/Spinner";

export const SelectHuevos = () => {
  //use context Socket
  const { pagina } = useContext(MostrarPaginaContext);

  const { cargarspinner } = useSpinner();

  return (
    <div className="container">
      <div className="center">
        <img alt="" width="100%" src={GLOBAL_HOTEL ==="HVB" ? logoHVB : logoHDA } style={{marginTop:"10px"}}></img>
      </div>
      

      {/* Para centrar el Radio Button con el boton al gusto */}

      <div className="center-align" style={{ color: "black", width: "100%" }}>
        {pagina === "Estilos" ? <RadioButtonIdioma /> : null}
        {pagina === "Enviar" ? null : <Pedido />}
      </div>

      {/* Seleccionar Estilos de Huevo */}
      {pagina === "Estilos" ? <Estilos /> : null}
      {pagina === "Cantidad" ? <CantidadEggs /> : null}
      {pagina === "Terminos" ? <Terminos /> : null}
      {pagina === "Ingredientes" ? <Ingredientes /> : null}
      {pagina === "Complementos" ? <Complementos /> : null}
      {pagina === "Enviar" ? ( cargarspinner ? <Spinner />  : <OrdenFinalizada />) : null}

      <AtrasSiguiente />
    </div>
  );
};

export default SelectHuevos;
