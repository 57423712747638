import React from "react";
const Error = ({ mensaje }) => (
  <span
    style={{
      margin: "3px",
      fontSize: "15px",
      fontWeight: "bold",
    }}
    className="new badge red"
    data-badge-caption=""
  >
    {mensaje}
  </span>
);

export default Error;
