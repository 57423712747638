import React, { useContext } from "react";

import { IdiomaContext } from "../context/IdiomaContext";

export const RadioButtonIdioma = () => {
  //use context Idioma
  const { idioma, usarSpanish, usarEnglish } = useContext(IdiomaContext);
  
  //Cambiar el idioma
  const onChangeidioma = (e) => {
    if (e.target.value === "es") {
      usarSpanish();
    } else {
      usarEnglish();
    }
  };

  const style = {
    flexGrow: "1",
  };
  


  return (
    <div className="input-field" style={{ display: "flex" }}>
      <div style={style}>
        <label>
          <input
            name="idioma"
            // disabled={pagina !=='Estilos'}
            type="radio"
            value="es"
            checked={idioma === "es"}
            onChange={onChangeidioma}
          />
          <span>Español</span>
        </label>
      </div>
      <div style={style}>
        <label>
          <input
            name="idioma"
            // disabled={pagina !=='Estilos'}
            type="radio"
            value="en"
            checked={idioma === "en"}
            onChange={onChangeidioma}
          />
          <span>English</span>
        </label>
      </div>
    </div>
  );
};

export default RadioButtonIdioma;
