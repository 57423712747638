import { useEffect, useState } from "react";

export const useSpinner = ( ) => {

    const [cargarspinner, setCargarSpinner] = useState(false);

    useEffect(()=>{

        setCargarSpinner(cargarspinner);

    }, [cargarspinner]);

    return {
        cargarspinner,
        setCargarSpinner
    }
}
