import React, { Fragment, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

import { OrdenContext } from "../../context/OrdenContext";
import { SocketContext } from "../../context/SocketContext";

const Finalizados = () => {
  //use context Socket
  const { socket } = useContext(SocketContext);

  const { ordenes_finalizadas } = useContext(OrdenContext);

  const onClick = (hotel, id) => {
    socket.emit("reactivar-orden-finalizada", hotel, id);
  };

  return (
    <Fragment>
      <div
        // Para evitar que la tablet seleccione el Texto y de opcion de buscar en google
        style={{
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          KhtmlUserSelect: "none",
          msUserSelect: "none",
        }}
      >
        <div className="card horizontal" 
          style={{ overflowY: "scroll" }}>
          <div className="card-stacked">
            <div className="card-content">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                }}
              >
                {ordenes_finalizadas.map((d) => {
                  return (
                    // Boton # Orden FINALIZADA
                    <div
                      key={uuidv4()}
                      onClick={() => {
                        onClick(d.hotel, d.id);
                      }}
                      style={{margin:"3px", fontSize: "2em" }}
                      className="btn"
                    >
                      {d.orden}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Finalizados;
