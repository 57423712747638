import React, { useContext, Fragment } from "react";

import { IdiomaContext } from "../context/IdiomaContext";
import { MenuContext } from "../context/MenuContext";


import BotonDescripcion from "./BotonDescripcion";



const Terminos = () => {
  //use context Idioma
  const { idioma } = useContext(IdiomaContext);
  const { menu } = useContext(MenuContext);

  const arrTerminos=menu.Terminos;
 
  return (
    <Fragment>
      <h5>
        {idioma === "es" ? "Seleccione termino" : "Choose term"}
      </h5>

      {arrTerminos.map((descripcion) => {
        return <BotonDescripcion key={descripcion.en} descripcion={descripcion} />;
      })}
    </Fragment>
  );
};

export default Terminos;
