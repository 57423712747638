import React, { useEffect, useContext } from "react";

import { SocketContext } from "../context/SocketContext";
import { OrdenContext } from "../context/OrdenContext";
import { MostrarPaginaContext } from "../context/MostrarPaginaContext";
import { IdiomaContext } from "../context/IdiomaContext";

import AtrasSiguiente from "../components/AtrasSiguiente";
import RadioButtonIdioma from "../components/RadioButtonIdioma";

import logoHVB from "../assets/img/logo-bvg.png";
import logoHDA from "../assets/img/logo-hda.png";
import { GLOBAL_HOTEL } from "../variables";

const Monitor = { es: "FAVOR DE ACERCARSE A LA ESTACION", en: "PLEASE GO TO STATION" };

const SiguienteTicket = () => {
  //use context Socket
  const { socket } = useContext(SocketContext);

  //use context Idioma
  const { idioma } = useContext(IdiomaContext);

  const { mostrarMonitor } = useContext(MostrarPaginaContext);

  const {
    ordenes_trabajando,
    guardarOrdenesTrabajando,
    guardarOrdenesPendientes,
    guardarOrdenesFinalizadas,
  } = useContext(OrdenContext);

  useEffect(() => {
    mostrarMonitor();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    socket.on("ordenes-pendientes", (data, callback) => {
      if (GLOBAL_HOTEL === data.hotel) {
        guardarOrdenesTrabajando([...data.trabajando]);
        guardarOrdenesFinalizadas([...data.finalizados]);
        guardarOrdenesPendientes([...data.pendientes]);
      }
    });

    return () => {
      socket.off("ordenes-pendientes");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <div className="container">
      <img alt="" width="100%" src={GLOBAL_HOTEL === "HVB" ? logoHVB : logoHDA} style={{ marginTop: "15px" }}></img>


      <div className="center-align" style={{ textAlign: "center" }}>
        <RadioButtonIdioma />
        <h5
          style={{
            fontWeight: "bold",
            fontFamily: "FreeMono, monospace",
            margin: "20px",
            color: "black",
          }}
        >
          {Monitor[`${idioma}`]}
        </h5>
        {ordenes_trabajando.length > 0 &&
          ordenes_trabajando.map((d) => <h2 key={d.id} /*style={{border:"1px solid #000000", color:"black"}}*/>{d.orden}</h2>)}
      </div>

      <AtrasSiguiente />

    </div>
  );
};

export default SiguienteTicket;
