import React, { useContext, Fragment } from "react";

import { IdiomaContext } from "../context/IdiomaContext";
import { MenuContext } from "../context/MenuContext";

import BotonDescripcion from "./BotonDescripcion";


const Ingredientes = () => {
  //use context Idioma
  const { idioma } = useContext(IdiomaContext);
  const { menu } = useContext(MenuContext);

  const arrIngredientes=menu.Ingredientes;

  return (
    <Fragment>
      <h5>
        {idioma === "es" ? "Seleccione ingredientes" : "Choose an ingredient"}
      </h5>
      
      {/* <BotonDescripcion descripcion={{es:'Todos', en:'All'}}/> */}

      {arrIngredientes.map((descripcion) => {
        return <BotonDescripcion key={descripcion.es} descripcion={descripcion} />;
      })}

      
    </Fragment>
  );
};

export default Ingredientes;
