import React, { createContext, useState, useEffect } from "react";
import { getMenus } from "../services/api";

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  //Orden
  const [menu, setMenu] = useState({
    Estilos: [],
    Ingredientes: [],
    Complementos: [],
    Terminos: [],
  });

  useEffect(() => {
    //Obtener desde una API el menu
    const ObtenerMenu = async () => {
      const data = await getMenus();
      setMenu(data.data);
    };

    ObtenerMenu();
  }, []);

  return (
    <MenuContext.Provider
      value={{
        menu,
        setMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
