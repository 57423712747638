//import axios from "axios";
import React, { createContext, useState, useEffect } from "react";

import { 
  GLOBAL_HOTEL,
} from "../variables";

import { getListadoOrdenes } from "../services/api";

export const OrdenContext = createContext();

export const OrdenProvider = ({ children }) => {
  //Orden
  const [orden, setOrden] = useState({
    id: 0,
    estilo: {},
    termino: {},
    ingredientes: [],
    complementos: [],
    cantidad: 1,
    estatus: 0,
    comentarios: "",
    hotel: GLOBAL_HOTEL
  });

  //OrdenesPendientes
  const [ordenes_pendientes, guardarOrdenesPendientes] = useState([]);
  const [ordenes_trabajando, guardarOrdenesTrabajando] = useState([]);
  const [ordenes_finalizadas, guardarOrdenesFinalizadas] = useState([]);

  const guardarInfo = (data) => {
    guardarOrdenesTrabajando([...data.trabajando]);
    guardarOrdenesFinalizadas([...data.finalizados]);
    guardarOrdenesPendientes([...data.pendientes]);
  };

  useEffect(() => {
    //Obtener desde una API las Pendientes
    const ObtenerInfo = async () => {
      const data = await getListadoOrdenes();
      guardarInfo(data.data);
    };

    ObtenerInfo();
  }, []);

  const setOrdenClean = () => {
    setOrden({
      id: 0,
      estilo: {},
      termino: {},
      ingredientes: [],
      complementos: [],
      cantidad: 1,
      estatus: 0,
      comentarios: "",
      hotel: GLOBAL_HOTEL,
    });
  };

  return (
    <OrdenContext.Provider
      value={{
        orden,
        ordenes_pendientes,
        ordenes_trabajando,
        ordenes_finalizadas,
        setOrden,
        setOrdenClean,
        guardarOrdenesPendientes,
        guardarOrdenesTrabajando,
        guardarOrdenesFinalizadas,
      }}
    >
      {children}
    </OrdenContext.Provider>
  );
};
