import React, { Fragment, useContext } from "react";

import { OrdenContext } from "../context/OrdenContext";
import { IdiomaContext } from "../context/IdiomaContext";

export const CantidadEggs = () => {
  const { orden, setOrden } = useContext(OrdenContext);

  //use context Idioma
  const { idioma } = useContext(IdiomaContext);

  const Sumar = () => {
    setOrden({
      ...orden,
      cantidad: orden.cantidad === 3 ? 3 : orden.cantidad + 1,
    });
  };

  const Restar = () => {
    setOrden({
      ...orden,
      cantidad: orden.cantidad <= 1 ? 1 : orden.cantidad - 1,
    });
  };

  return (
    <Fragment>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}
      >
        <h5>{idioma === "es" ? "Cantidad de Huevos" : "Quantity of eggs"}</h5>
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
      >
        <button
          className="btn-floating btn-large scale-transition"
          style={{ width: "50px", height: "50px", marginTop: "7px" }}
          onClick={Restar}
        >
          <i className="fa fa-minus"></i>
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <input
          style={{
            padding: "10px",
            background: "white",
            borderBottom: "none",
            textAlign: "center",
            width: "14%",
          }}
          className="counter"
          type="text"
          placeholder="value..."
          onChange={() => {}}
          value={orden.cantidad}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button
          className="btn-floating btn-large scale-transition"
          style={{ width: "50px", height: "50px", marginTop: "7px" }}
          onClick={Sumar}
        >
          <i className="fa fa-plus"></i>
        </button>

      </div>
    </Fragment>
  );
};

export default CantidadEggs;
