import React, { useContext } from "react";

import { IdiomaContext } from "../context/IdiomaContext";
import { MostrarPaginaContext } from "../context/MostrarPaginaContext";
import { OrdenContext } from "../context/OrdenContext";

const BotonDescripcion = ({ descripcion }) => {
  //use context Idioma
  const { idioma } = useContext(IdiomaContext);

  //use context MostrarPagina
  const {
    pagina,
  } = useContext(MostrarPaginaContext);

  const { orden, setOrden } = useContext(OrdenContext);

  //Evento onClik
  const onClick = (e, descripcion) => {

    let existe = false;

    switch (pagina) {
      case "Estilos":
        //Se actualiza el estilo de la orden en el Context
        setOrden({
          ...orden,
          estilo: descripcion,
        });

        break;
      case "Terminos":
        setOrden({
          ...orden,
          termino: descripcion,
        });

        break;
      case "Ingredientes":
        orden.ingredientes.map((ingrediente) => {
          if (ingrediente[`${idioma}`] === descripcion[`${idioma}`])
            existe = true;
          return ingrediente;
        });

        if (!existe) {
          setOrden({
            ...orden,
            ingredientes: [...orden.ingredientes, descripcion],
          });
        }

        break;
      case "Complementos":

        orden.complementos.map((complemento) => {
          if (complemento[`${idioma}`] === descripcion[`${idioma}`])
            existe = true;
          return complemento;
        });

        if (!existe) {
          setOrden({
            ...orden,
            complementos: [...orden.complementos, descripcion],
          });
        }

        break;
      default:
    }
  };

  return (
    <div
      style={{
        margin: "4px",
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <div style={{ margin: "4px", flexBasis: "100%" }}>
        <button
          style={{ width: "100%" }}
          className="waves-effect waves-light buttons-felx btn"
          onClick={(e) => {
            onClick(e, descripcion);
          }}
        >
          {descripcion[`${idioma}`]}
        </button>
      </div>
    </div>
  );
};

export default BotonDescripcion;
