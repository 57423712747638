import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";

import { SocketContext } from "../../context/SocketContext";

import SpanBoton from "./SpanBoton";

const BotonPedido = ({ ord_pendiente, color }) => {
  //use context Socket
  const { socket } = useContext(SocketContext);

  const {
    estilo,
    ingredientes,
    termino,
    complementos,
    cantidad,
    orden,
    id,
    comentarios,
    hotel
  } = ord_pendiente;

  const onClick = (hotel, id) => {
    socket.emit("trabajar-orden", hotel, id);
  };

  return (
    
    // Evento Click para cambiar el estatus del Pedido a TRABAJANDO
    <div
      onClick={() => onClick(hotel, id)}
      style={{
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        KhtmlUserSelect: "none",
        msUserSelect: "none",
      }}
    >
      {/* Boton General: # Orden + Descripcion del Pedido */}
      <div
        style={{
          marginTop: ".5em",
          height: "auto",
          display: "flex",
          alignItems: "stretch",
        }}
      >
        {/* Boton #Orden */}
        <div
          style={{
            color: "black",
            fontSize: "2em",
            margin: "6px",
            height: "auto",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            verticalAlign: "middle",
            lineHeight: "auto",
          }}
          className={`btn ${color}`}
        >
          {orden}
        </div>

        {/* Boton Cantidad + Estilo + Complementos */}
        <div
          style={{
            color: "black",
            fontSize: "2em",
            margin: "6px",
            height: "auto",
            width: "100%",
          }}
          className="btn orange lighten-2"
        >
          {cantidad} - {estilo}
          {/* Span Ingredientes */}
          <div
            className="valign-wrapper"
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {!!termino && (
              <SpanBoton descripcion={termino} color="deep-orange accent-3" />
            )}

            {!!ingredientes &&
              ingredientes.map((ingrediente) => {
                return (
                  <SpanBoton
                    key={uuidv4()}
                    descripcion={ingrediente}
                    color="blue"
                  />
                );
              })}

            {!!complementos &&
              complementos.map((complemento) => {
                return (
                  <SpanBoton
                    key={uuidv4()}
                    descripcion={complemento}
                    color="brown lighten-2"
                  />
                );
              })}
              {!!comentarios && <SpanBoton descripcion={comentarios} color="purple lighten-3"/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotonPedido;
