import React from 'react';

const SpanBoton = ({descripcion, color}) => {
    return ( 
        <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          margin: "3px",
          fontSize: "25px",
          fontWeight: "bold",
          height:"auto"
        }}
        className={`new badge ${color}`}
        data-badge-caption=""
      >
        {descripcion}
      </span>

     );
}
 
export default SpanBoton;
