import React, { Fragment, useEffect, useContext } from "react";

import Pendientes from "../components/pendientes/Pendientes";
import Trabajando from "../components/pendientes/Trabajando";
import Finalizados from "../components/pendientes/Finalizados";

import { SocketContext } from "../context/SocketContext";
import { OrdenContext } from "../context/OrdenContext";

import {
  getListadoOrdenes,
} from "../services/api";

import { 
  GLOBAL_HOTEL,
} from "../variables";


const PendientesPage = () => {
  //use context Socket
  const { socket } = useContext(SocketContext);

  const {
    guardarOrdenesTrabajando,
    guardarOrdenesPendientes,
    guardarOrdenesFinalizadas,
  } = useContext(OrdenContext);

  const guardarInfo = (data) => {
    guardarOrdenesTrabajando([...data.trabajando]);
    guardarOrdenesFinalizadas([...data.finalizados]);
    guardarOrdenesPendientes([...data.pendientes]);
  };
  
  //API: Para obtener el listado de colas al iniciar la pagina
  useEffect(() => {
    //Obtener desde una API las Pendientes
    const ObtenerInfo = async () => {
      const data = await getListadoOrdenes();
      guardarInfo(data.data);
    };

    ObtenerInfo();
    // eslint-disable-next-line
  }, []);

  //Socket: ordenes-pendientes
  useEffect(() => {
    socket.on("ordenes-pendientes", (data, callback) => {
      if(GLOBAL_HOTEL === data.hotel){
        guardarInfo(data);
      }
    });

    return () => {
      socket.off("ordenes-pendientes");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <Fragment>
      <div className="section">
        <div className="row">
          <div className="col s6">
            <Pendientes titulo="Pendientes" />
          </div>
          <div className="col s6">
            <Trabajando />
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            <Finalizados />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PendientesPage;
