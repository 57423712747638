import React, { useContext } from "react";

import { IdiomaContext } from "../context/IdiomaContext";
import { OrdenContext } from "../context/OrdenContext";

export const Pedido = () => {
  //use context Idioma
  const { idioma } = useContext(IdiomaContext);

  const { orden, setOrden } = useContext(OrdenContext);

  let labelEstilo ='';
  if(Object.keys(orden.estilo).length > 0 )labelEstilo = orden.cantidad + " - " + orden.estilo[`${idioma}`];

  return (
    <div
      style={{
        marginTop: "1em",
        height: "auto",
        display: "flex",
        alignItems: "stretch",
      }}
    >
      <div
        style={{
          color: "black",
          fontSize: "2em",
          margin: "6px",
          width: "100%",
          height: "auto",
        }}
        className="btn grey lighten-4"
      >
        {labelEstilo}
        <div
          className="valign-wrapper"
          style={{
            display: "flex",
            flexWrap: "wrap",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          { Object.keys(orden.termino).length === 0 ? null : (
            <span key={orden.termino[`${idioma}`]}
              onClick={() => {
                setOrden({
                  ...orden,
                  termino: "",
                });
              }}
              style={{
                margin: "3px",
                fontSize: "15px",
                fontWeight: "bold",
              }}
              className="new badge deep-orange accent-3"
              data-badge-caption=""
            >
              {orden.termino[`${idioma}`]}
            </span>
          )}
          {orden.ingredientes.map((ingrediente_ord) => {
            return (
              <span key={ingrediente_ord[`${idioma}`]}
                onClick={() => {
                  const newIng = orden.ingredientes.filter(
                    (ingrediente) =>
                      ingrediente[`${idioma}`] !== ingrediente_ord[`${idioma}`]
                  );

                  setOrden({
                    ...orden,
                    ingredientes: [...newIng],
                  });
                }}
                style={{
                  margin: "3px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                className="new badge blue"
                data-badge-caption=""
              >
                {ingrediente_ord[`${idioma}`]}
              </span>
            );
          })}
          {orden.complementos.map((compl_ord) => {
            return (
              <span key={compl_ord[`${idioma}`]}
                onClick={() => {
                  const newCom = orden.complementos.filter(
                    (compl) => compl[`${idioma}`] !== compl_ord[`${idioma}`]
                  );

                  setOrden({
                    ...orden,
                    complementos: [...newCom],
                  });
                }}
                style={{
                  margin: "3px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                className="new badge brown lighten-2"
                data-badge-caption=""
              >
                {compl_ord[`${idioma}`]}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Pedido;
