import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";

import { OrdenContext } from "../../context/OrdenContext";
import BotonPedidoTrabajando from "./BotonPedidoTrabajando";

const Trabajando = () => {
  const { ordenes_trabajando } = useContext(OrdenContext);

  const style = {
    
    width: "100%",
  };

  return (
    <div style={style} >
      <h4 className="header center">Trabajando</h4>
      <div
        className="card horizontal"
        style={{ height: "30em", overflow: "auto" }}
      >
        <div className="card-stacked">
          <div className="card-content">
            <ul className="collection">
              {ordenes_trabajando.map((d) => (
                <BotonPedidoTrabajando key={uuidv4()} ord_trabajando={d} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trabajando;
