import React, { useContext, Fragment } from "react";

import { IdiomaContext } from "../context/IdiomaContext";
import { OrdenContext } from "../context/OrdenContext";
import { MenuContext } from "../context/MenuContext";

import BotonDescripcion from "./BotonDescripcion";

const Complementos = () => {
  //use context Idioma
  const { idioma } = useContext(IdiomaContext);
  const { menu } = useContext(MenuContext);
  const { orden, setOrden } = useContext(OrdenContext);

  const arrComplementos=menu.Complementos;

  const onChange = (e) => {
    setOrden({
      ...orden,
      comentarios: e.target.value,
    });
  };

  return (
    <Fragment>
      <h5>
        {idioma === "es" ? "Seleccione Complemento" : "Choose an complement"}
      </h5>

      {/* <BotonDescripcion descripcion={{es:'Todos', en:'All'}}/> */}

      {arrComplementos.map((descripcion) => {
        return (
          <BotonDescripcion
            key={descripcion.es + descripcion.en}
            descripcion={descripcion}
          />
        );
      })}

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
      >
        <textarea
          style={{ background: "white", height: "50px" }}
          onChange={onChange}
          placeholder={
            idioma === "es" ? "Comentarios adicionales" : "Additional comments"
          }
        ></textarea>
      </div>
    </Fragment>
  );
};

export default Complementos;
