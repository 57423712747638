import React from "react";

import "./App.css";
import "font-awesome/css/font-awesome.min.css";

import { IdiomaProvider } from "./context/IdiomaContext";
import { MostrarPaginaProvider } from "./context/MostrarPaginaContext";
import { SocketProvider } from "./context/SocketContext";
import { OrdenProvider } from "./context/OrdenContext";
import { MenuProvider } from "./context/MenuContext";

import { RouterPage } from "./pages/RouterPage";

const App = () => {
  return (
    <SocketProvider>
      <MostrarPaginaProvider>
        <IdiomaProvider>
          <OrdenProvider>
            <MenuProvider>
              <RouterPage />
            </MenuProvider>
          </OrdenProvider>
        </IdiomaProvider>
      </MostrarPaginaProvider>
    </SocketProvider>
  );
};

export default App;
