import React, { createContext } from 'react';
import { useSocket } from '../hooks/useSocket';

import { 
    SOCKET_URL,
 } from "../variables";

export const SocketContext = createContext();


export const SocketProvider = ({ children }) => {

    //Usamos el Hook personalizado para realizar la conexion al socket.
    const { socket, online } = useSocket(SOCKET_URL);

    return (
        <SocketContext.Provider 
            value={{ 
                socket, 
                online 
            }}>
                { children }
        </SocketContext.Provider>
    )
}

