import React, { createContext, useState } from "react";

export const IdiomaContext = createContext();

export const IdiomaProvider = ({ children }) => {
  //Idioma que se usara en la plataforma
  const [idioma, setUsarIdioma] = useState("es");

  const usarSpanish = () => {
    setUsarIdioma("es");
  };

  const usarEnglish = () => {
    setUsarIdioma("en");
  };

  return (
    <IdiomaContext.Provider
      value={{
        idioma,
        usarSpanish,
        usarEnglish,
      }}
    >
      {children}
    </IdiomaContext.Provider>
  );
};
