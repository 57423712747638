import React, { Fragment, useEffect, useContext } from "react";

import { SocketContext } from "../context/SocketContext";
import { OrdenContext } from "../context/OrdenContext";

import {
  GLOBAL_HOTEL,
} from "../variables";

const Tv = () => {
  //use context Socket
  const { socket } = useContext(SocketContext);

  const {
    ordenes_trabajando,
    guardarOrdenesTrabajando,
    guardarOrdenesPendientes,
    guardarOrdenesFinalizadas,
  } = useContext(OrdenContext);

  useEffect(() => {
    socket.on("ordenes-pendientes", (data, callback) => {
      if (GLOBAL_HOTEL === data.hotel) {
        guardarOrdenesTrabajando([...data.trabajando]);
        guardarOrdenesFinalizadas([...data.finalizados]);
        guardarOrdenesPendientes([...data.pendientes]);
      }
    });

    return () => {
      socket.off("ordenes-pendientes");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);


  return (
    <Fragment>
      <div className="section black" style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}>
        <div className="row center">
          <h1
            className="white-text"
            style={{
              fontSize: "3em",
              fontWeight: "bold",
              fontFamily: "FreeMono, monospace",
            }}
          >
            HUEVOS AL GUSTO / OMELETTE STATION
          </h1>
        </div>

        <div className="row center">
          {ordenes_trabajando.length > 0 &&
            ordenes_trabajando.map((d) => {
              return (
                <div key={d.id} className="col s12 m3">
                  <h1

                    className="white-text"
                    style={{
                      fontSize: "6em",
                      border: "2px solid white",
                    }}
                  >
                    {d.orden}
                  </h1>
                </div>
              );
            })}
        </div>

        <div className="row center" style={{ position: "absolute", bottom: 10, left: 10 }}>
          <h1
            className="white-text"
            style={{
              fontSize: "3em",
              fontWeight: "bold",
              fontFamily: "FreeMono, monospace",
            }}
          >
            FAVOR DE ACERCARSE A LA ESTACION / PLEASE GO TO STATION
          </h1>
        </div>

      </div>

    </Fragment>
  );
};

export default Tv;
