import React, { Fragment, useContext } from "react";

import { IdiomaContext } from "../context/IdiomaContext";
import { OrdenContext } from "../context/OrdenContext";

export const OrdenFinalizada = () => {
  const { orden } = useContext(OrdenContext);
  //use context Idioma
  const { idioma } = useContext(IdiomaContext);

  const divs = {
    backgroundColor: "gainsboro",
    width: "200px",
    margin: "10px",
    textAlign: "center",
    lineHeight: "200px",
    fontSize: "50px",
    border: "2px solid black",
    borderRadius: "100%",
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div>
          <h4>{idioma === "es" ? "Numero de orden" : "Order Number"}</h4>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          marginTop: "5%",
        }}
      >
        <div 
         style={divs}
        >{orden.id}</div>
      </div>

    </Fragment>
  );
};

export default OrdenFinalizada;
