import React, { useContext, Fragment } from "react";

import { IdiomaContext } from "../context/IdiomaContext";
import { MenuContext } from "../context/MenuContext";

import BotonDescripcion from "./BotonDescripcion";

const Estilos = () => {
  //use context Idioma
  const { idioma } = useContext(IdiomaContext);
  const { menu } = useContext(MenuContext);

  const arrEstilos = menu.Estilos;

  return (
    <Fragment>
      <h5>
        {idioma === "es" ? "Seleccione tipo de huevo" : "Choose a kind of egg"}
      </h5>

      {arrEstilos.map((descripcion) => {
        return (
          <BotonDescripcion key={descripcion[`es`]} descripcion={descripcion} />
        );
      })}
    </Fragment>
  );
};

export default Estilos;
