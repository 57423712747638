import axios from "axios";

import { 
    API_URL,
    GLOBAL_HOTEL,
  } from "../variables";

export const getMenus = async ()=>{
    const data = await axios.get(`${API_URL}/menu?hotel=${GLOBAL_HOTEL}`);
    return data;
}

export const getListadoOrdenes = async ()=>{
    const data = await axios.get(`${API_URL}/listOrdenes?hotel=${GLOBAL_HOTEL}`);
    return data;
}
export const getPendientes = async ()=>{
    const data = await axios.get(`${API_URL}/listPendientes?hotel=${GLOBAL_HOTEL}`);
    return data;
}

export const getTrabajando = async ()=>{
    return await fetch(`${API_URL}/listTrabajando?hotel=${GLOBAL_HOTEL}`,{
        method:"GET",
        Accept: "application/json",
        mode:"cors"
    });
}

export const getFinalizados = async ()=>{
    return await fetch(`${API_URL}/listFinalizados?hotel=${GLOBAL_HOTEL}`,{
        method:"GET",
        mode:"cors",
    });
}
