import React, {createContext, useState} from 'react';

export const MostrarPaginaContext = createContext();

export const MostrarPaginaProvider = ({children})=>{
    
    //Idioma que se usara en la plataforma
    const [ pagina, setPagina ] = useState('Estilos');

    const mostrarMonitor = () =>{
        setPagina('Monitor');
    };

    const mostrarEstilos = () =>{
        setPagina('Estilos');
    };

    const mostrarCantidad = () =>{
        setPagina('Cantidad');
    };

    const mostrarTerminos = () =>{
        setPagina('Terminos');
    };

    const mostrarIngredientes = () =>{
        setPagina('Ingredientes');
    };

    const mostrarComplementos = () =>{
        setPagina('Complementos');
    };

    const mostrarEnviar = () =>{
        setPagina('Enviar');
    };

    

    return (
        <MostrarPaginaContext.Provider
            value = {{
                pagina,
                mostrarEstilos,
                mostrarTerminos,
                mostrarIngredientes,
                mostrarComplementos,
                mostrarCantidad,
                mostrarEnviar,
                mostrarMonitor,
            }}
        >
            { children }
        </MostrarPaginaContext.Provider>
    )

}