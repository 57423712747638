import React, { useContext } from "react";

import { IdiomaContext } from "../context/IdiomaContext";
import { MostrarPaginaContext } from "../context/MostrarPaginaContext";
import { OrdenContext } from "../context/OrdenContext";

import { SocketContext } from "../context/SocketContext";

import { useSpinner } from "../hooks/useSpinner";

const Atras = { es: "Atras", en: "Back" };
const Siguiente = { es: "Siguiente", en: "Next" };
const Enviar = { es: "Enviar", en: "Send" };
const NuevaOrden = { es: "Nueva Orden", en: "New Ticket" };
const Monitor = { es: "Monitor", en: "Display" };


export const AtrasSiguiente = () => {
  //use context Socket
  const { socket, online } = useContext(SocketContext);

  //use context Idioma
  const { idioma } = useContext(IdiomaContext);

  //use context MostrarPagina
  const {
    pagina,
    mostrarTerminos,
    mostrarIngredientes,
    mostrarComplementos,
    mostrarEstilos,
    mostrarCantidad,
    mostrarEnviar,
  } = useContext(MostrarPaginaContext);

  const { orden, setOrden, setOrdenClean } = useContext(OrdenContext);

  const { setCargarSpinner } = useSpinner();

  const fnAtras = () => {
    switch (pagina) {
      case "Cantidad":
        mostrarEstilos();
        break;
      case "Terminos":
        if (orden.estilo[`es`] === "Estrellados" || orden.estilo[`es`] === "Rancheros") mostrarCantidad();
        else mostrarIngredientes();
        break;
      case "Ingredientes":
        mostrarCantidad();
        break;
      case "Complementos":
        if (
          orden.estilo[`es`] === "Revueltos" ||
          orden.estilo[`es`] === "Revueltos(clara)"
        ) {
          mostrarIngredientes();
        } else {
          mostrarTerminos();
        }

        break;
      default:
        break;
    }
  };

  const fnSiguiente = () => {
    
    switch (pagina) {
      case "Estilos":
        if (Object.keys(orden.estilo).length !== 0) mostrarCantidad();
        break;
      case "Cantidad":
        if (orden.estilo[`es`] !== "Estrellados" && orden.estilo[`es`] !== "Rancheros") {
          mostrarIngredientes();
        } else {
          mostrarTerminos();
        }
        break;
      case "Terminos":
        if (Object.keys(orden.termino).length !== 0) {
          //mostrarComplementos();
          /* Se esta eliminando la opcion de Complementos por eso se comento la linea anterior, y se agregan las siguientes 3 lineas para enviar la orden */
          setCargarSpinner(true);
          mostrarEnviar();
          GuardarOrden();
        }
        break;
      case "Ingredientes":
        if (
          orden.estilo[`${idioma}`] === "Revueltos" ||
          orden.estilo[`${idioma}`] === "Revueltos(clara)"
        ) {
          //mostrarComplementos();
          /* Se esta eliminando la opcion de Complementos por eso se comento la linea anterior, y se agregan las siguientes 3 lineas para enviar la orden */
          setCargarSpinner(true);
          mostrarEnviar();
          GuardarOrden();
        } else {
          mostrarTerminos();
        }
        break;
      case "Complementos":
        setCargarSpinner(true);
        mostrarEnviar();
        GuardarOrden();
        break;
      case "Enviar":
        mostrarEstilos();
        setOrdenClean();
        break;
      default:
        break;
    }
  };

  //TODO: Ir a crear la Orden en el Back
  const GuardarOrden = async () => {
    //Solicitar Ticket
    socket.emit("crear-orden", orden, (numeroOrden) => {
      setOrden({
        ...orden,
        id: numeroOrden,
      });

      setCargarSpinner(false);
    });
  };

  const onClick = (descripcion) => {
    if (descripcion[`es`] === "Atras") fnAtras();
    else fnSiguiente();
  };

  return (
    <div style={{ marginTop: "5%", flexBasis: "100%" }}>
      <div style={{ display: "flex" }}>
        {pagina === "Estilos" || pagina === "Enviar" || pagina === "Monitor" ? (
          <a
          disabled={!online}
            href={pagina === "Monitor" ? "/" : "/siguiente"}
            style={pagina === "Monitor" ? { width: "33%", margin: "30px" }: { width: "100%", margin: "5px" }}
            className="waves-effect waves-light   buttons-felx btn"
            //onClick={() => onClick(Atras)}
          >
            {pagina === "Monitor" ? NuevaOrden[`${idioma}`] : Monitor[`${idioma}`]}
          </a>
        ) : (
          <button
          disabled={!online}
            style={{ width: "100%", margin: "5px" }}
            className="waves-effect waves-light   buttons-felx btn"
            onClick={() => onClick(Atras)}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
            &nbsp; {Atras[`${idioma}`]}
          </button>
        )}

        <label style={{ width: "30%", margin: "5px" }}>
          {/* <i className="fa fa-check-circle" aria-hidden="true"></i> */}
        </label>

        {pagina === "Monitor" ? null : (
          <button
            disabled={!online || 
              ( pagina === "Terminos" ? Object.keys(orden.termino).length === 0 : Object.keys(orden.estilo).length === 0)}
            style={{ width: "100%", margin: "5px" }}
            className="waves-effect waves-light   buttons-felx btn"
            onClick={() =>
              onClick(pagina === "Complementos" ? Enviar : Siguiente)
            }
          >
            {(pagina === "Complementos" || pagina === "Terminos" || pagina === "Ingredientes" )
              ? ( pagina === "Ingredientes" 
                    ? (
                      orden.estilo[`${idioma}`] === "Revueltos" ||
                      orden.estilo[`${idioma}`] === "Revueltos(clara)"
                      ) 
                        ? Enviar[`${idioma}`]
                        : Siguiente[`${idioma}`]
                    : Enviar[`${idioma}`]
                )
              : (pagina === "Enviar"
                  ? NuevaOrden[`${idioma}`]
                  : Siguiente[`${idioma}`]
                )
            }
            &nbsp;
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default AtrasSiguiente;
