import React, {useContext} from "react";
import { v4 as uuidv4 } from "uuid";

import { OrdenContext } from "../../context/OrdenContext";

import BotonPedido from "./BotonPedido";


const Pendientes = ({ titulo }) => {
    const {
        ordenes_pendientes,
      } = useContext(OrdenContext);


  const style = {
    width: "100%",
  };

  return (
    <div style={style}>
      <h4 className="header center">{titulo}</h4>
      <div
        className="card horizontal"
        style={{ height: "30em", overflow: "auto" }}
      >
        <div className="card-stacked">
          <div className="card-content">
            <ul className="collection">
              {ordenes_pendientes.map((ord_pendiente) => {
                return (
                  <BotonPedido key={uuidv4()} ord_pendiente={ord_pendiente} color={"orange lighten-2"} />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pendientes;
