import React, { Fragment, useContext } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { SocketContext } from "../context/SocketContext";

import Error from "../components/Error";
//Pages
import SelectHuevos from "./selectHuevos";
import PendientesPage from "./PendientesPage";
import Tv2 from "./Tv";
import SiguienteTicket from "./SiguienteTicket";

export const RouterPage = () => {
  const { online } = useContext(SocketContext);

  return (
    <Fragment>
      <div
        className="container"
        style={{ position: "fixed", right: "25px", top: "5px" }}
      >
        {online ? null : <Error mensaje="Server Offline" />}
      </div>

      <Router>
        <Switch>
          <Route exact path="/" component={SelectHuevos} />
          <Route exact path="/list" component={PendientesPage} />
          <Route exact path="/tv" component={Tv2} />
          <Route exact path="/siguiente" component={SiguienteTicket} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </Fragment>
  );
};
